import axios from 'axios'

let serviceUrl = ''

switch (process.env.GATSBY_ACTIVE_ENV) {
  case 'production':
    serviceUrl = 'https://lowcost-origination-orchestrator.orquesta.tijuana.mesh-servicios-fnd.mx/v2'
    break
  case 'qa':
  case 'staging':
  case 'test':
    serviceUrl = 'https://lowcost-origination-orchestrator.orquesta.calidad-architect.com/v2'
    break
  default:
    serviceUrl = 'https://lowcost-origination-orchestrator.orquesta.calidad-architect.com/v2'
}

export const LandingLowCostInstance = axios.create({
  baseURL: serviceUrl,
  timeout: 90000
})
